
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { TextArray } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmStylizedText,
    TmModal,
    TmButton,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
  },
})
